import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

/**
 * Helper function that initializes animations on elements with the attribute 'ck-countup-element'
 */
const initAnimations = () => {
  // Selects all elements with the attribute 'ck-countup-element'
  document.querySelectorAll('[ck-countup-element]').forEach((number) => {
    // The following lines get various configuration attributes or set defaults
    const startCount = number.getAttribute('ck-countup-start') || '0'
    const endCount = number.getAttribute('ck-countup-end') || number.textContent || '100'
    const startTrigger = number.getAttribute('ck-countup-start-trigger') || 'top bottom'
    const endTrigger = number.getAttribute('ck-countup-end-trigger') || 'bottom top'
    const startTriggerMobile =
      number.getAttribute('ck-countup-start-trigger-mobile') ||
      number.getAttribute('ck-countup-start-trigger') ||
      'top bottom'
    const endTriggerMobile =
      number.getAttribute('ck-countup-end-trigger-mobile') ||
      number.getAttribute('ck-countup-end-trigger') ||
      'bottom top'
    const locale = number.getAttribute('ck-countup-locale')
    const num = { var: startCount }

    /**
     * Updates innerHTML of number element with formatted variable
     */
    const changeNumber = () => {
      const variable = typeof num.var === 'string' ? parseFloat(num.var) : num.var
      if (locale) {
        number.innerHTML = variable.toLocaleString(locale, { maximumFractionDigits: 0 })
      } else {
        number.innerHTML = variable.toFixed()
      }
    }

    // if desktop, ie screen width is wider than 991px, use normal triggers
    if (window.innerWidth > 991) {
      // Creates a timeline for the animation with a scroll trigger
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: number,
          start: startTrigger,
          end: endTrigger,
        },
      })
      tl.fromTo(num, { var: 0 }, { var: endCount, duration: 1, ease: 'none', onUpdate: changeNumber })
    } else {
      // Creates a timeline for the animation with a scroll trigger, using mobile triggers
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: number,
          start: startTriggerMobile,
          end: endTriggerMobile,
        },
      })
      tl.fromTo(num, { var: 0 }, { var: endCount, duration: 1, ease: 'none', onUpdate: changeNumber })
    }
  })
  
}

/**
 * Main function that initiates the animation by calling initAnimations function
 */
export default function countUp() {
  initAnimations()
}
