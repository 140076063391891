import { convertTag } from '../lib/helpers'

const ESCAPE_KEY = 'Escape'

/**
 * Checks if window is currently desktop-size.
 *
 * @param {MediaQueryList} mediaQuery - The media query object to check against.
 * @returns {boolean} - True if the viewport is matching the media query, false otherwise.
 */
function isDesktop(mediaQuery: MediaQueryList) {
  return mediaQuery.matches
}

/**
 * Controls the behavior of dropdown menus in a navigation bar.
 */
export default function NavDropdowns() {
  /**
   * Finds all dropdown toggles and sets up event listeners and respective classes.
   * Manages state for mobile and non-mobile displays.
   *
   * example usage:
   * <div ck-nav-dropdown-parent>
   *   <div ck-nav-dropdown-toggle="menu-id"></div> <!-- this will be converted into a button -->
   *   <div id="menu-id">
   *     [links here]
   *     <button ck-nav-dropdown-close></button> <!-- optional close button -->
   *   </div>
   * </div>
   */
  Array.from(document.querySelectorAll('[ck-nav-dropdown-toggle]')).forEach((toggle) => {
    // basic state management
    let opened = false
    let mobileOnly = false

    // handle scenarios in which dropdowns
    // should only function on mobile:
    if (!(toggle.getAttribute('ck-mobile-only') === null)) {
      mobileOnly = true
      let mobileBreakpoint = toggle.getAttribute('ck-mobile-only')
      if (mobileBreakpoint === '#') mobileBreakpoint = '991'
      const mobileBreakpointForMediaQuery = parseInt(mobileBreakpoint!) + 1
      const mediaQuery = window.matchMedia(`(min-width: ${mobileBreakpointForMediaQuery}px)`)

      if (isDesktop(mediaQuery)) {
        toggle.removeAttribute('aria-expanded')
      } else {
        toggle.setAttribute('aria-expanded', 'false')
      }

      // when window resized, re-check if desktop or mobile
      let timeoutFunctionId: NodeJS.Timeout
      window.addEventListener('resize', () => {
        clearTimeout(timeoutFunctionId)
        timeoutFunctionId = setTimeout(() => {
          if (isDesktop(mediaQuery) && opened) {
            closeDropdown()
            toggle.removeAttribute('aria-expanded')
          }
          if (isDesktop(mediaQuery)) toggle.removeAttribute('aria-expanded')
          if (!isDesktop && opened) {
            toggle.setAttribute('aria-expanded', 'true')
          }
        }, 350)
      })
    }

    // for all other dropdowns:
    const dropdownID = toggle.getAttribute('ck-nav-dropdown-toggle')
    // if a target id is specified,
    if (!(dropdownID === '#') && !(dropdownID === null)) {
      // try to find a target element with that id
      const dropdown = document.getElementById(dropdownID)
      if (!dropdown) return
      // if found, add appropriate roles
      dropdown.setAttribute('role', 'menu')
      const menuLinks = dropdown.getElementsByTagName('a')
      Array.from(menuLinks).forEach((link) => {
        link.setAttribute('role', 'menuitem')
      })
    }

    // format the dropdown toggle itself
    toggle = convertTag(toggle as HTMLElement, 'button')
    toggle.setAttribute('type', 'button')

    // if allowed to function on desktop, label as unexpanded on page load
    if (!mobileOnly) toggle.setAttribute('aria-expanded', 'false')

    const dropdownParent = toggle.parentElement!

    /**
     * Opens the dropdown menu by adding relevant classes and setting appropriate ARIA attributes.
     */
    function openDropdown() {
      // label as expanded
      toggle.setAttribute('aria-expanded', 'true')
      // add .open class to parent to visually "open" dropdown
      // note: this assumes your CSS will handle hide/show properly
      toggle.parentElement?.classList.add('open')
      // update opened "state"
      opened = true
      // listen for click events outside dropdown
      document.addEventListener('click', closeDropdownOnOutsideClick)
      // listen for escape key press
      document.addEventListener('keyup', closeDropdownOnEscPress)
    }

    /**
     * Closes the dropdown menu by removing relevant classes and setting appropriate ARIA attributes.
     */
    function closeDropdown() {
      // label as collapsed
      toggle.setAttribute('aria-expanded', 'false')
      // remove .open class from parent to visually "close" dropdown
      toggle.parentElement?.classList.remove('open')
      // update opened "state"
      opened = false
      // remove listeners since no longer needed
      document.removeEventListener('click', closeDropdownOnOutsideClick)
      document.removeEventListener('keyup', closeDropdownOnEscPress)
    }

    /**
     * Event handler that closes the dropdown when a click occurs outside it.
     *
     * @param {Event} event - The event triggered by user interaction.
     */
    const closeDropdownOnOutsideClick = (event: Event) => {
      if (!dropdownParent!.contains(event.target as Node)) {
        closeDropdown()
      }
    }

    /**
     * Event handler that closes the dropdown upon pressing the Escape key.
     *
     * @param {KeyboardEvent} event - The keyboard event triggered by the user.
     */
    function closeDropdownOnEscPress(event: KeyboardEvent) {
      if (event.key === ESCAPE_KEY) {
        event.preventDefault()
        closeDropdown()
        ;(toggle as HTMLElement).focus()
      }
    }

    /**
     * Event handler that toggles the dropdown state when its associated button is clicked.
     *
     * @param {Event} event - The event triggered by user interaction.
     */
    function handleToggleClick(event: Event) {
      event.preventDefault()
      if (!opened) {
        openDropdown()
      } else {
        closeDropdown()
      }
    }

    // attach click listener to toggle
    toggle.addEventListener('click', (event) => {
      handleToggleClick(event)
    })

    // find any close buttons
    Array.from(document.querySelectorAll('[ck-nav-dropdown-close]')).forEach((closer) => {
      const dropdownParent = closer.closest('[ck-nav-dropdown-parent]')
      if (!dropdownParent) return
      closer.addEventListener('click', function () {
        if (dropdownParent.classList.contains('open')) {
          closeDropdown()
        }
      })
    })
  })

  // hoist .w--current up to parent dropdown, if present
  Array.from(document.querySelectorAll('.w--current:not(.never-current)')).forEach((currentItem) => {
    const dropdownParent = currentItem.closest('[ck-nav-dropdown-parent]')
    if (!dropdownParent) return
    // add .w--current to the element just inside the dropdown parent, for styling
    dropdownParent.firstElementChild!.classList.add('w--current')
  })
}