import { focusableSelectorsString } from '../lib/helpers'

/**
 * Initialize and bind event listeners for accessible navigation components.
 * Ensures that keyboard navigation works as expected within the component and provides utilities for opening and closing navigation.
 * 
 * @example
 * ```html
 * <header ck-nav-component> <!-- this gets class of .open added when open -->
 *     <nav>
 *         <a href="/">Logo</a>
 *         <div ck-nav> <!-- this is what gets shown/hidden -->
 *             <ul>
 *                 <li><a href="#">Link 1</a></li>
 *                 <li><a href="#">Link 2</a></li>
 *             </ul>
 *             <button ck-nav-close>Close</button> <!-- optional -->
 *         </div>
 *         <button ck-nav-toggle="navbar-id">Toggle Menu</button>
 *     </nav>
 * </header>
 * ```
 */
export default function initializeAccessibleNavs(): void {
  document.querySelectorAll<HTMLElement>('[ck-nav-component]').forEach((component) => {
    const nav = component.querySelector<HTMLElement>('[ck-nav]')
    if (!nav) return

    let lastFocus: HTMLElement | null = null
    let navOpen = false

    /**
     * Handle tab key within the navigation component to ensure that focus remains trapped.
     * @param {KeyboardEvent} e - The keydown event object.
     */
    const handleTabKey = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') return

      const focusableEls = Array.from(component.querySelectorAll<HTMLElement>(focusableSelectorsString))
      const [firstFocusableEl, lastFocusableEl] = [focusableEls[0], focusableEls[focusableEls.length - 1]]

      if (
        (e.shiftKey && document.activeElement === firstFocusableEl) ||
        (!e.shiftKey && document.activeElement === lastFocusableEl)
      ) {
        setTimeout(() => (e.shiftKey ? lastFocusableEl : firstFocusableEl).focus(), 10)
        e.preventDefault()
      }
    }

    /**
     * Handler for keydown events when navigation is open. Listens for 'Escape' to close the nav and for tabbing logic.
     * @param {KeyboardEvent} e - The keydown event object.
     */
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') closeNav()
      else handleTabKey(e)
    }

    /**
     * Close the navigation component and remove event listeners.
     */
    const closeNav = () => {
      document.body.style.overflow = ''
      component.classList.remove('open')
      component
        .querySelectorAll<HTMLElement>('[ck-nav-toggle]')
        .forEach((toggle) => toggle.setAttribute('aria-expanded', 'false'))

      navOpen = false
      document.removeEventListener('keydown', keydownHandler)
      if (lastFocus) lastFocus.focus()
    }

    /**
     * Show the navigation component and add event listeners.
     */
    const showNav = () => {
      document.body.style.overflow = 'hidden'
      component.classList.add('open')
      component
        .querySelectorAll<HTMLElement>('[ck-nav-toggle]')
        .forEach((toggle) => toggle.setAttribute('aria-expanded', 'true'))

      document.addEventListener('keydown', keydownHandler)

      document.addEventListener('click', (e) => {
        if (nav.style.display !== 'block') return
        const navBoundary = nav.getBoundingClientRect()
        if (
          !(
            e.clientX >= navBoundary.left &&
            e.clientX <= navBoundary.right &&
            e.clientY >= navBoundary.top &&
            e.clientY <= navBoundary.bottom
          )
        )
          closeNav()
      })

      navOpen = true
      lastFocus = document.activeElement as HTMLElement
      setTimeout(() => nav.focus(), 300)
    }

    // Initialize event listeners for nav toggle buttons.
    component.querySelectorAll<HTMLElement>('[ck-nav-toggle]').forEach((navbarToggle) => {
      navbarToggle.addEventListener('click', () => (navOpen ? closeNav() : showNav()))
    })

    // Initialize event listeners for nav close buttons.
    component.querySelectorAll<HTMLElement>('[ck-dialog-close]').forEach((closeButton) => {
      closeButton.addEventListener('click', closeNav)
    })
  })
}
