import Accordions from './components/accordion'
import Banners from './components/banners'
import Cards from './components/card'
import Dialogs from './components/dialog'
import Dropdowns from './components/dropdown'
import Navs from './components/nav'

import CountUp from './components/countUp'

console.info('%c🔥CK Started!', 'color: red; font-size: 2rem;')

// accessibility enhancements
Accordions()
Banners()
Cards()
Dialogs()
Dropdowns()
Navs()

// gsap
CountUp()