/**
 * Function to convert an HTML element to a different tag type while keeping the same attributes and children.
 *
 * @param {HTMLElement} el - The original HTML element that will be converted.
 * @param {string} tag - The new tag type the original element will be converted to.
 * @returns {HTMLElement} The new HTML element with the specified tag, attributes and children of the original element.
 */
export const convertTag = (el: HTMLElement, tag: string) => {
  const newElement: HTMLElement = document.createElement(tag)
  newElement.innerHTML = el.innerHTML
  Array.from(el.attributes).forEach((attr) => {
    newElement.setAttribute(attr.name, attr.value)
  })
  el.parentNode?.replaceChild(newElement, el)
  return newElement
}

/**
 * Function to set focus on an HTML element.
 * Source: https://gist.github.com/patrickfox/ee5a0d093e0ab9f76441f8339ab4b8e1
 *
 * @param {HTMLElement} el - The HTML element to set focus on.
 * @param {string | boolean} placeFocusBefore - If true, focus will be placed before the element. If a string, focus will be placed before the element and the string will be inserted as a text node.
 */
export const access = (el: HTMLElement, placeFocusBefore: string | boolean) => {
  let focusMethod: any, ogti: Maybe<string>, tempEl: HTMLElement

  const onblurEl = function (_e: FocusEvent) {
    if (el.getAttribute('data-ogti') && ogti) {
      el.setAttribute('tabindex', ogti)
    } else {
      el.removeAttribute('tabindex')
    }
    el.removeAttribute('data-ogti')
    el.removeEventListener('focusout', focusMethod)
  }
  const onblurTempEl = function (_e: FocusEvent) {
    tempEl.removeEventListener('focusout', focusMethod)!
    tempEl.parentNode?.removeChild(tempEl)
  }
  const focusEl = function (theEl: HTMLElement) {
    theEl.setAttribute('tabindex', '-1')
    theEl.addEventListener('focusout', focusMethod)
    theEl.focus()
  }
  focusMethod = onblurEl
  if (placeFocusBefore) {
    tempEl = document.createElement('span')
    if (typeof placeFocusBefore === 'string') {
      tempEl.innerHTML = placeFocusBefore
    }
    tempEl.setAttribute(
      'style',
      'position: absolute;height: 1px;width: 1px;margin: -1px;padding: 0;overflow: hidden;clip: rect(0 0 0 0);border: 0;',
    )
    const newEl = el.parentNode?.insertBefore(tempEl, el)
    focusMethod = onblurTempEl

    if (newEl) focusEl(newEl)
  } else {
    ogti = el.getAttribute('tabindex')
    if (ogti) {
      el.setAttribute('data-ogti', ogti)
    }
    focusEl(el)
  }
}

interface CallbackFunction {
  (...args: any[]): void
}

/**
 * Function to throttle a callback function.
 *
 * @param {CallbackFunction} callbackFunction - The callback function to throttle.
 * @param {number} limit - The number of milliseconds to throttle the callback function.
 * @returns {CallbackFunction} The throttled callback function.
 */
export function throttle(callbackFunction: CallbackFunction, limit: number) {
  let tick = false
  return function () {
    if (!tick) {
      callbackFunction()
      tick = true
      setTimeout(function () {
        tick = false
      }, limit)
    }
  }
}

export const focusableSelectors = [
  'a[href]:not([tabindex^="-"])',
  'area[href]:not([tabindex^="-"])',
  'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
  'input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked',
  'select:not([disabled]):not([tabindex^="-"])',
  'textarea:not([disabled]):not([tabindex^="-"])',
  'button:not([disabled]):not([tabindex^="-"])',
  'iframe:not([tabindex^="-"])',
  'audio[controls]:not([tabindex^="-"])',
  'video[controls]:not([tabindex^="-"])',
  '[contenteditable]:not([tabindex^="-"])',
  '[tabindex]:not([tabindex^="-"])',
]

export const focusableSelectorsString =
  'a[href]:not([tabindex^="-"]), area[href]:not([tabindex^="-"]), input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"]), input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked, select:not([disabled]):not([tabindex^="-"]), textarea:not([disabled]):not([tabindex^="-"]), button:not([disabled]):not([tabindex^="-"]), iframe:not([tabindex^="-"]), audio[controls]:not([tabindex^="-"]), video[controls]:not([tabindex^="-"]), [contenteditable]:not([tabindex^="-"]), [tabindex]:not([tabindex^="-"])' 