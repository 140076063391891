/**
 * Returns all cookies as an object.
 * @returns {Record<string, string>} - An object containing all cookies.
 */
// function getCookies(): Record<string, string> {
//   return document.cookie.split(';').reduce((cookies, item) => {
//     const [name, value] = item.split('=');
//     cookies[name.trim()] = value;
//     return cookies;
//   }, {});
// }

/**
 * Checks if banner should display. If cookie "showBanner" is set to false, the banner will not display. Cookies last for 24 hours.
 */
// const shouldCloseBanner = (): boolean => {
//   const cookies = getCookies()

//   // devs can add showBanner=true to the URL to see the banner
//   if (window.location.search.includes('showBanner=true')) {
//     // remove cookie
//     document.cookie = 'showBanner=; max-age=0'
//     return false
//   }

//   if (cookies['showBanner'] === 'false') {
//     return true
//   } else {
//     return false
//   }
// }

/**
 * Closes a banner component.
 */
// const closeBanner = (component: HTMLElement, bypassCookieCheck = false): void => {
//   if (!bypassCookieCheck && getCookies()['showBanner'] !== 'false') {
//     document.cookie = 'showBanner=false; max-age=86400'
//   }

//   component.remove()
//   // remove page padding to account for banner
//   document.body.style.paddingTop = ''
//   // remove nav space to account for banner
//   const nav = document.querySelector<HTMLElement>('[ck-nav-component]')
//   if (nav) {
//     nav.style.top = ''
//   }
// }


/**
 * Initializes accessible banners and binds event listeners.
 * 
 * @example
 * ```html
 * <div ck-banner-component>
 *  <p>This is a banner</p>
 *  <button ck-banner-close>Close Banner</button>
 * </div>
 * ```
 */
export default function BannerComponent(): void {
  const components = document.querySelectorAll<HTMLElement>('[ck-banner-component]')

  components.forEach((component) => {
    // if (shouldCloseBanner()) {
    //   closeBanner(component)
    //   return
    // }

    // show banner
    component.style.display = 'block'
    // add page padding to account for banner
    document.body.style.paddingTop = `${component.clientHeight}px`
    // add nav space to account for banner
    const nav = document.querySelector<HTMLElement>('[ck-nav-component]')
    if (nav) {
      nav.style.top = `${component.clientHeight}px`
    }

    // add event listener to close button
    // const closeButton = component.querySelector('[ck-banner-close]')
    // if (!closeButton) {
    //   console.error(`No close button found within banner component ${component}`)
    //   return
    // }
    // closeButton.addEventListener('click', () => closeBanner(component))
  })
}