import { convertTag } from '../lib/helpers'

/**
 * Handles focus event on a card.
 * Adds 'focused' class to the card.
 *
 * @param {any} card - The card HTML element that received focus
 */
const handleCardFocus = (card: any) => {
  card.classList.add('focused')
}

/**
 * Handles blur event on a card.
 * Removes 'focused' and 'flipped' classes from the card if present.
 *
 * @param {any} card - The card HTML element that lost focus
 */
const handleCardBlur = (card: any) => {
  card.classList.remove('focused')
  if (card.classList.contains('flipped')) {
    card.classList.remove('flipped')
  }
}

/**
 * Converts a card HTML element into a CKCard.
 * Sets up necessary attributes and event listeners for the card.
 *
 * @param {HTMLElement} cardElement - The card HTML element to convert
 */
const createCKCard = (cardElement: HTMLElement) => {
  const cardFrontElement = cardElement.querySelector<HTMLElement>('[ck-card-front]')

  if (!cardFrontElement) return

  const cardFront = convertTag(cardFrontElement, 'button')

  // Set 'type' and 'tabindex' attributes of cardFront
  cardFront.setAttribute('type', 'button')
  cardFront.setAttribute('tabindex', '0')

  // Give cardFront aria-label="Learn more about {card front content}"
  const cardFrontContent = cardFront.textContent || ''
  cardFront.setAttribute('aria-label', `Learn more about ${cardFrontContent}`)

  cardFront.addEventListener('blur', () => handleCardBlur(cardElement))
  cardFront.addEventListener('focus', () => handleCardFocus(cardElement))

  cardElement.addEventListener('click', () => {
    if (!cardElement.classList.contains('flipped')) {
      cardElement.classList.add('flipped')

      document.addEventListener('click', function (event) {
        if (!cardElement.contains(event.target as Node)) {
          cardElement.classList.remove('flipped')
        }
      })
    } else {
      cardElement.classList.remove('flipped')
    }
  })
}

/**
 * Converts all card HTML elements on the page into CKCards.
 */
const Cards = () => {
  Array.from(document.querySelectorAll('[ck-card]')).forEach((card) => createCKCard(card as HTMLElement))
}

export default Cards
